<template>
  <div>
    <weekdays />
    <div class="flix-form-group flix-mt-50">
      <h4>{{ $tc("time.pause.title", 2) }}</h4>
      <div
        class="flix-flex flix-flex-center flix-gap-10"
        style="justify-content: flex-start"
      >
        {{ $t("time.pause.info") }}
        <pauseIcon id="coffee-mug" style="transform: scale(.8)" />
      </div>
    </div>
    <div v-if="checkData()" class="flix-form-group flix-mt-50">
      <h4>
        {{ $t("time.lastMinute.title") }}
        <help>{{ $t("time.lastMinute.info") }}</help>
      </h4>
      <div class="flix-form-group">
        <lastMinute />
      </div>
      <div class="flix-form-group">
        <timeAdvance />
      </div>
      <div
        class="flix-flex flix-flex-list flix-gap-10 flix-form-group flix-mt-50"
        style="align-items: flex-start"
      >
        <h4>
          {{ $t("time.period.title") }}
          <help>{{ $t("time.period.info") }}</help>
        </h4>
        <div class="flix-flex flix-flex-inline flix-flex-center flix-gap-10">
          <div class="flix-html-small">
            {{ $t("time.period.description").split("{date}")[0] }}
          </div>
          <startDate class="item" />
          <div class="flix-html-small">
            {{ $t("time.period.description").split("{date}")[1] }}
          </div>
          <endDate class="item" />
          <div class="flix-html-small">
            {{ $t("time.period.description").split("{date}")[2] }}
          </div>
        </div>
        <checkDate />
      </div>

      <saveBtn v-if="checkData()" class="saveBtn" :callback="setSave" />
    </div>
  </div>
</template>
<script>
import def from "@/components/business/default/index.vue";
export default {
  components: {
    pauseIcon() {
      return import("@/components/business/tableau/pauseIcon");
    },
    weekdays() {
      return import("@/components/business/time/weekdays");
    },
    startDate() {
      return import("@/components/business/time/startDate");
    },
    checkDate() {
      return import("@/components/business/time/checkDate");
    },
    endDate() {
      return import("@/components/business/time/endDate");
    },
    timeAdvance() {
      return import("@/components/business/settings/advance");
    },
    lastMinute() {
      return import("@/components/business/settings/lastMinute");
    }
    /* pause() {
      return import("@/components/business/time/pause");
    } */
  },
  extends: def,
  methods: {
    nextStep() {
      this.$router.push({ name: "applicants" });
    },
    checkData() {
      var data = JSON.parse(JSON.stringify(this.$store.state.business.unsaved));
      // if (!data.title) {
      // return false
      // }
      if (!Object.keys(data.include.weekdays).length) {
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="sass" scoped>
.startEndDates
  display: flex
  align-items: flex-start
  justify-content: flex-start
  flex-direction: row
  .item
    text-align: left
    flex: 1
.saveBtn
  margin-top: 30px

@media(max-width: 500px)
  .startEndDates
    flex-direction: column
</style>
